

























import { Component, Vue } from "vue-property-decorator";
import { settingsServices } from "@/services/settings.service";
@Component
export default class TaxInvoiceSerialNumberAlert extends Vue {
  response: string[] = [];

  getTaxInvoiceSerialNumberReminder() {
    settingsServices
      .taxInvoiceSerialNumberReminder()
      .then(response => (this.response = response));
  }
  created() {
    this.getTaxInvoiceSerialNumberReminder();
  }
}
