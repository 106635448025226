var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      staticStyle: {
        "background-color": "#fff",
        "border-radius": "4px",
        padding: "0",
        color: "black"
      },
      attrs: {
        type: "flex",
        title: _vm.$t("lbl_tax_invoice_serial_number_reminder")
      }
    },
    [
      _c(
        "a-row",
        { attrs: { align: "middle" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            _vm._l(_vm.response, function(data, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticStyle: {
                    "background-color": "#fff",
                    display: "block",
                    "line-height": "1.5rem"
                  }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "blink_me",
                      staticStyle: {
                        color: "red",
                        margin: "0",
                        display: "block",
                        "font-size": "1.2rem"
                      }
                    },
                    [_vm._v(" " + _vm._s(data) + " ")]
                  )
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }